import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Antd from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';

const baseUrl = process.env.NODE_ENV === 'development'
    ? "http://192.168.1.236:8001"
    : "https://staging.idosclinic.com";

axios.defaults.baseURL = `${baseUrl}/api`;
axios.interceptors.request.use((config) => {
    const userData = store.getters.getUserData;

    const newConfig = {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${(userData || {}).token}`,
        },
    };

    return newConfig;
});

const app = createApp(App)
    .use(store)
    .use(router)
    .use(CKEditor)
    .use(Vuex)
    .use(VueSweetalert2)
    .use(VueAxios, axios)
    .use(Antd);
app.config.globalProperties.$baseFileUrl = baseUrl;

app.provide("axios", axios);
app.provide("server", baseUrl);
app.provide("vm", app);

app.mount("#app");
